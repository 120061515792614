import React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImage } from "gatsby-plugin-storyblok-image";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";
import { getSprinkles } from "~styles/getSprinkles.css";

import { isGifUrl, isSvgUrl } from "~utils/__deprecated__/asset_utils";

import { Box } from "../Box";

import type { IGatsbyImageData } from "gatsby-plugin-image";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export interface ImageProps extends GetSprinklesArgs, StoryblokBlok {
  caption?: string;
  className?: string;
  hasPadding?: boolean;
  image?: StoryblokFieldMedia;
  imgClassName?: string;
  isCard?: boolean;
  loading?: "eager" | "lazy";
  isStaticImage?: boolean;
}

export function Image({
  caption,
  className: userClassName,
  imgClassName: userImgClassName,
  hasPadding,
  image,
  loading = "lazy",
  isCard,
  objectFit = "cover",
  borderRadius,
  border,
  overflow,
  ...rest
}: ImageProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);
  const { filename, alt = "null" } = image || {};
  if (!filename) return null;

  if (isSvgUrl(filename) || isGifUrl(filename)) {
    return (
      <img
        data-testid="image-svg-gif"
        className={clsx(
          userClassName,
          getSprinkles({
            ...atomProps,
            borderRadius: isCard ? "sm" : borderRadius,
            border: isCard ? "default" : border,
            padding: hasPadding ? "spacing3" : "none",
            maxWidth: "100%",
          })
        )}
        src={filename}
        alt={alt || ""}
      />
    );
  }

  const gatsbyImageData: IGatsbyImageData | null = getGatsbyImage(filename, {
    layout: "constrained",
  });

  if (!gatsbyImageData) return null;

  /**
   * If there is a caption, wrap markup in a container, which can
   * optionally be styled as a card
   */
  if (caption) {
    return (
      <Box
        data-testid="image-caption"
        className={clsx(
          userClassName,
          { [variantBackgroundColor.background_white]: isCard },
          getSprinkles({
            ...atomProps,
            borderRadius: isCard ? "sm" : borderRadius,
            border: isCard ? "default" : border,
            overflow: isCard ? "hidden" : overflow,
            padding: hasPadding ? "spacing3" : "none",
            maxWidth: "100%",
          })
        )}
        {...otherProps}
      >
        <GatsbyImage
          alt={alt || "null"}
          loading={loading}
          objectFit={objectFit}
          image={gatsbyImageData}
          className={userClassName}
          imgClassName={userImgClassName}
        />

        <Box as="figcaption" textAppearance="caption">
          {caption}
        </Box>
      </Box>
    );
  }

  return (
    <GatsbyImage
      data-testid="image-default"
      alt={alt || "null"}
      loading={loading}
      objectFit={objectFit}
      image={gatsbyImageData}
      className={clsx(
        userClassName,
        getSprinkles({
          ...atomProps,
          borderRadius: isCard ? "sm" : borderRadius,
          border: isCard ? "default" : border,
          padding: hasPadding ? "spacing3" : "none",
          maxWidth: "100%",
        })
      )}
      imgClassName={clsx(
        userImgClassName,
        getSprinkles({
          borderRadius: isCard ? "sm" : borderRadius,
          overflow: isCard ? "hidden" : overflow,
        })
      )}
      {...otherProps}
    />
  );
}
